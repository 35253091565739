import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useMediaQuery } from 'react-responsive';
import ReactMarkdown from 'react-markdown';
import Layout from 'src/components/layout';
import SEO from 'src/components/seo';
import Signup from 'src/components/Signup';
import { RightArrowIcon, LeftArrowIcon } from 'src/assets/svg';

const Story = ({
  pageContext: {
    prevStoryId,
    nextStoryId,
    participantName,
    photo,
    thumb,
    testimonial,
    excerpt,
    pullQuote,
    storyId,
    pilotCity,
  },
  ...rest
}) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1280px)',
  });

  return (
    <Layout>
      <SEO
        title={participantName}
        description={`Learn about ${participantName}'s experiences with guaranteed income.`}
        image={`https://guaranteedincome.us/images/${storyId}.jpg`}
      />
      <main className={`pb-8 bg-tan`}>
        <div className="container px-4 lg:px-0 md:mx-auto flex items-center mt-6 lg:mt-8 flex-wrap">
          <Link to={`/`}>
            <p className="text-[14px] font-[800] text-[#C4C4C4] mb-0">HOME</p>
          </Link>
          <div className="mx-2 h-[14px] border border-[#C4C4C4] mb-0" />
          <Link to={`/stories`}>
            <p className="text-[14px] font-[800] text-[#C4C4C4] mb-0">STORIES</p>
          </Link>
          <div className="mx-2 h-[14px] border border-[#C4C4C4]" />
          <p className="text-[14px] font-[800] uppercase mb-0">{participantName}</p>
        </div>
        <div className="container mx-auto mt-4 sm:mt-7 lg:mt-10">
          {isDesktop ? null : <h1 className="mb-4 px-4 lg:px-0 break-all">{participantName}</h1>}
          <div className="flex flex-col sm:flex-row justify-between px-0 sm:px-4 lg:px-0">
            <div className="w-full sm:w-[300px] lg:w-[560px] xl:w-[600px] mt-4 sm:mt-0 px-4 sm:px-0 order-1 sm:order-0">
              {isDesktop ? <h1 className="mb-5 break-all">{participantName}</h1> : null}
              <div className="storyContent text-[16px] sm:text-[21px] font-[400]">
                <ReactMarkdown children={testimonial} />
              </div>
              <div className="flex mt-3 sm:mt-5 xl:mt-6">
                {prevStoryId && (
                  <Link to={`/stories/${prevStoryId}`} className="mr-auto">
                    <button className="flex items-center text-[13px] lg:text-[14px] text-[#B26F16] font-[800] tracking-[1.8px]">
                      <LeftArrowIcon className="w-[17px] h-[12px] mr-2" iconColor="#B26F16" />
                      PREVIOUS STORY
                    </button>
                  </Link>
                )}
                {nextStoryId && (
                  <Link to={`/stories/${nextStoryId}`} className="ml-auto">
                    <button className="flex items-center text-[13px] lg:text-[14px] text-[#B26F16] font-[800] tracking-[1.8px]">
                      NEXT STORY
                      <RightArrowIcon className="w-[17px] h-[12px] ml-2" iconColor="#B26F16" />
                    </button>
                  </Link>
                )}
              </div>
            </div>
            <div className="w-full sm:w-[320px] lg:w-[427px] order-0 sm:order-1">
              <GatsbyImage
                className={`story-images story-image-${pilotCity.toLowerCase()}-${participantName.toLowerCase()} w-full h-[320px] lg:h-[427px] object-cover object-top`}
                image={getImage(photo)}
                alt={participantName}
              />
              <p className="text-[#B26F16] text-[24px] lg-[30px] font-[800] mt-4 px-4 sm:px-0">
                {pullQuote}
              </p>
            </div>
          </div>
        </div>
      </main>
      <Signup />
    </Layout>
  );
};

export default Story;
